export default {
  TWITTER_AT: '@Wizards',
  GIT_COMMIT: '1b680b438c545ac7992dfeaf74cbe785de10d9d8',
  CI_JOB_ID: '9604992862',
  LOCATOR_VERSION: 'local_version',
  EVENT_RESERVATION_GRAPHQL_SERVICE: 'https://api.tabletop.wizards.com/silverbeak-griffin-service/graphql',
  GOOGLE_ANALYTICS_ID: 'UA-15020098-23',
  GOOGLE_TAG_MANAGER_ID: 'GTM-5QMQ67K',
  WOTC_ANALYTICS_URL: 'https://sel.api.bi.wizards.com/'

}
